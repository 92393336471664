import React from 'react';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import ActiveDot from 'client/app/components/nav/breadcrumbs/components/ActiveDot';
import SubItemContainer from 'client/app/components/nav/breadcrumbs/components/SubItemContainer';
import {
  type SimulationEntityItem,
  NavNodeSubItem,
} from 'client/app/components/nav/breadcrumbs/types';
import Colors from 'common/ui/Colors';

type Props = {
  isActive: boolean;
  item: NavNodeSubItem;
  icon: React.ReactElement;
  onClick: React.MouseEventHandler<HTMLElement>;
};

export default function SimulationEntityItem({ item, isActive, icon, onClick }: Props) {
  const simulation = item as SimulationEntityItem;
  const timestamp = moment(simulation.createdAt).format('YYYY-MM-DD HH:mm');

  return (
    <Container data-heap-tracking="breadcrumbs-simulation-entity-item" onClick={onClick}>
      {isActive ? <ActiveDot /> : <span />}
      {icon}
      <Stack gap={1}>
        <Typography variant="subtitle2" color="textPrimary">
          {simulation.name}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {timestamp}
        </Typography>
      </Stack>
      {simulation.seriesPart && (
        <SimulationSeriesChip
          label={<SeriesPartLabel>Part {simulation.seriesPart}</SeriesPartLabel>}
        />
      )}
    </Container>
  );
}

const Container = styled(SubItemContainer)`
  grid-template-columns: 5px 24px 1fr 60px;
`;

const SimulationSeriesChip = styled(Chip)`
  padding: 0;
  height: 20px;
  background-color: ${Colors.BLUE_5};
`;

const SeriesPartLabel = styled(Typography)`
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
`;
