import React from 'react';

import { styled } from '@mui/material/styles';

import MarkExecutionCompletedButton from 'client/app/apps/simulation-details/MarkExecutionCompletedButton';
import { SimulationWithExecution } from 'client/app/apps/simulation-details/overview/results/ResultsScreen';
import { useIsDOEAllowedForSimulation } from 'client/app/apps/simulation-details/SimulationDetails';
import StructureDataButton from 'client/app/apps/simulation-details/StructureDataButton';
import { SimulationOrExecutionStatusesEnum } from 'client/app/gql';
import { formatShortTransitiveSimulationStatus } from 'client/app/lib/formatTransitiveSimulationStatus';
import { hasDispenserOrManualDevice } from 'client/app/lib/workflow/deviceConfigUtils';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { Deck } from 'common/types/mix';
import CommonHeader, {
  CommonHeaderInfo,
} from 'common/ui/components/simulation-details/CommonHeader';

type Props = {
  simulationwithExecution: SimulationWithExecution;
  deck?: Deck;
};

const ExecutionDetailsHeader = React.memo(({ simulationwithExecution, deck }: Props) => {
  const isEnabledBreadcrumbs = useFeatureToggle('BREADCRUMBS');
  // Convert to a format the CommonHeader understands.
  // The CommonHeader is used in AnthaHub, too.
  const executionHeaderInfo: CommonHeaderInfo = {
    name: isEnabledBreadcrumbs ? 'Execution overview' : simulationwithExecution.name,
    submitterName: simulationwithExecution.user.displayName,
    dateCreated: new Date(simulationwithExecution.startedAt),
  };

  const isNewDOEEnabled = useFeatureToggle('NEW_DOE');

  const DOESimulationType = useIsDOEAllowedForSimulation(simulationwithExecution);

  const isDispenserOrManual = hasDispenserOrManualDevice(
    simulationwithExecution.workflow.workflow.Config,
  );

  const canStructureData = isNewDOEEnabled && DOESimulationType === 'new' && !!deck;

  const showStructureDataButton = isDispenserOrManual
    ? simulationwithExecution.transitiveStatus ===
      SimulationOrExecutionStatusesEnum.EXECUTION_SUCCESS
    : true;

  return (
    <CommonHeader
      headerInfo={executionHeaderInfo}
      estimatedTimeSeconds={simulationwithExecution.timeEstimateSeconds || null}
      status={formatShortTransitiveSimulationStatus(
        simulationwithExecution.transitiveStatus,
      )}
      creationAction="Created"
      createdAtAction="Created"
      endAdornment={
        <ButtonsBox>
          {canStructureData && showStructureDataButton ? (
            <StructureDataButton simulation={simulationwithExecution} deck={deck} />
          ) : isDispenserOrManual ? (
            <MarkExecutionCompletedButton
              simulationWithExecution={simulationwithExecution}
            />
          ) : null}
        </ButtonsBox>
      }
    />
  );
});

const ButtonsBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
});

export default ExecutionDetailsHeader;
