import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';

import { useLoadAll } from 'client/app/api/PlateTypesApi';
import InstructionsStep from 'client/app/apps/simulation-details/instructions/InstructionsStep';
import { GroupsByStep } from 'client/app/apps/simulation-details/instructions/types';
import { PlateType } from 'common/types/plateType';
import { Step } from 'common/types/steps';

type Props = {
  steps: Step[];
  groups: GroupsByStep;
};

export default function Instructions({ steps, groups }: Props) {
  const loadPlates = useLoadAll();
  const [platesMap, setPlatesMap] = useState<Record<string, PlateType>>();

  useEffect(() => {
    void loadPlates().then(plates => {
      setPlatesMap(Object.fromEntries(plates.map(plate => [plate.id, plate])));
    });
  }, [loadPlates]);

  if (!platesMap) {
    return null;
  }

  return (
    <Wrapper>
      {steps.map((step, i) => (
        <InstructionsStep
          step={step}
          plates={platesMap}
          key={i}
          number={i + 1}
          showConcentration
          elementName={groups[step.id]?.element?.name}
        />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(6, 6, 6, 4),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),
  overflow: 'auto',
}));
