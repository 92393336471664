import React, { useMemo, useState } from 'react';

import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/HelpOutline';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import ResourcesCard from 'client/app/apps/simulation-details/overview/ResourcesCard';
import ParameterEditor from 'client/app/components/Parameters/ParameterEditor';
import { SimulationQuery } from 'client/app/gql';
import { EditorType } from 'common/elementConfiguration/EditorType';
import doNothing from 'common/lib/doNothing';
import { formatVolume, formatVolumeObj, pluralizeWord, roundUp } from 'common/lib/format';
import { byName } from 'common/lib/strings';
import {
  divide,
  greaterThan,
  greaterThanEqual,
  isCompatible,
  lessThan,
} from 'common/lib/units';
import { Measurement } from 'common/types/mix';
import IconButtonWithPopper from 'common/ui/components/IconButtonWithPopper';
import Tabs, { TabsInfo } from 'common/ui/components/Tabs';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  reagents: Reagent[];
};

enum TabIds {
  STOCKS = 'stocks',
  INPUTS = 'inputs',
}

const TABS: TabsInfo<TabIds> = [
  { value: TabIds.STOCKS, label: 'Stocks' },
  { value: TabIds.INPUTS, label: 'Inputs' },
];

export default function ReagentsList({ reagents }: Props) {
  const classes = useStyles();

  const [extraVolume, setExtraVolume] = useState<number | null>(null);

  const [roundUpEnabled, setRoundUpEnabled] = useState(false);

  const sortedReagents = useMemo(() => {
    const maybeRound = roundUpEnabled ? roundUp : (val: number) => val;

    return [...reagents].sort(byName).map(r => ({
      ...r,
      volumeUl: maybeRound(r.volumeUl + (extraVolume || 0.0)),
    }));
  }, [extraVolume, reagents, roundUpEnabled]);

  const [stockConcentrations, setStockConcentrations] = useState(
    getDefaultStockConcentrations(sortedReagents),
  );

  const onUpdateStock = (name: string, value: Measurement) => {
    const newConcs = [...stockConcentrations];
    const conc = getConcentration(newConcs, name, value.unit)!;
    conc.concentration = value;
    setStockConcentrations(newConcs);
  };

  const [tabId, setTabId] = useState(TabIds.INPUTS);

  return (
    <ResourcesCard header="Reagents">
      <Tabs
        tabsInfo={TABS}
        activeTab={tabId}
        onChangeTab={tab => setTabId(tab)}
        className={classes.tabs}
      />
      <Box hidden={tabId !== TabIds.STOCKS} className={classes.box}>
        <StockView
          stockConcentrations={stockConcentrations}
          onUpdateStock={onUpdateStock}
          reagents={sortedReagents}
        />
      </Box>
      <Box hidden={tabId !== TabIds.INPUTS} className={classes.box}>
        <Box>
          <div className={classes.option}>
            <IconButtonWithPopper
              content={
                <Typography variant="caption">
                  Increase the volume to make of each input liquid
                </Typography>
              }
              iconButtonProps={{ size: 'xsmall', icon: <HelpIcon /> }}
              onClick={doNothing} //TODO: Update with logging
            />
            <TextField
              label="Extra Volume"
              className={classes.extraVolume}
              placeholder="0"
              value={extraVolume}
              type="number"
              size="small"
              InputProps={{
                endAdornment: <InputAdornment position="end">ul</InputAdornment>,
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setExtraVolume(parseFloat(event.target.value));
              }}
            />
          </div>

          <div className={classes.option}>
            <Typography variant="subtitle2">Round Up Volumes</Typography>
            <IconButtonWithPopper
              content={
                <Typography variant="caption">
                  Round totals up to nearest 2 significant figures
                </Typography>
              }
              iconButtonProps={{ size: 'xsmall', icon: <HelpIcon /> }}
              onClick={doNothing} //TODO: Update with logging
            />
            <div>
              <ParameterEditor
                anthaType="bool"
                value={roundUpEnabled}
                onChange={setRoundUpEnabled}
                editorType={EditorType.TOGGLE}
              />
            </div>
          </div>
        </Box>
        {sortedReagents?.map((reagent, idx) => (
          <ReagentAccordion
            key={idx}
            reagent={reagent}
            stockConcentrations={stockConcentrations}
          />
        ))}
      </Box>
    </ResourcesCard>
  );
}

type Reagent = Exclude<SimulationQuery['simulation']['reagents'], null>[number];

type StockConcentration = {
  name: string;
  concentration: Measurement;
};

function getConcentration(
  stocks: StockConcentration[],
  name: string,
  unit: string,
): StockConcentration | undefined {
  return stocks.find(el => el.name === name && isCompatible(el.concentration.unit, unit));
}

type ReagentAccordionProps = {
  reagent: Reagent;
  stockConcentrations: StockConcentration[];
};

function ReagentAccordion({ reagent, stockConcentrations }: ReagentAccordionProps) {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  let secondaryLabel = `${reagent.solutes.length} ${pluralizeWord(
    reagent.solutes.length,
    'component',
  )}`;
  if (reagent.solutes.length === 0) {
    secondaryLabel = '';
  }
  if (reagent.solutes.length === 1 && reagent.solutes[0].name === reagent.name) {
    secondaryLabel = formatVolumeObj(reagent.solutes[0].concentration, false);
  }

  const requiredVolumes = useMemo(
    () => getRequiredSoluteVolumes(stockConcentrations, reagent),
    [stockConcentrations, reagent],
  );

  const volumeFromStocks = useMemo(
    () =>
      [...requiredVolumes.keys()].reduce(function (v, curr) {
        return v + requiredVolumes.get(curr)!.value;
      }, 0.0),
    [requiredVolumes],
  );
  const remainingVolume = reagent.volumeUl - volumeFromStocks;

  const isError = remainingVolume < 0.0;

  return (
    <Accordion
      expanded={isError || expanded}
      onChange={(event: React.ChangeEvent<{}>, isExpanded: boolean) =>
        setExpanded(isExpanded)
      }
    >
      <AccordionSummary
        expandIcon={
          isError ? <ErrorIcon color="error" fontSize="small" /> : <ExpandMoreIcon />
        }
        className={classes.reagentSummary}
      >
        <Typography className={classes.heading} color={isError ? 'error' : 'initial'}>
          {reagent.name}
        </Typography>
        <Typography className={classes.secondaryHeading}>{secondaryLabel}</Typography>
        <Typography className={classes.volumeHeading}>
          {formatVolume(reagent.volumeUl, 'ul')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.reagentTable}>
        <Box>
          {reagent.solutes?.length > 0 && (
            <>
              <Typography variant="subtitle2">Contents</Typography>
              <Table size="small" aria-label="contents" className={classes.soluteTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>Stock</TableCell>
                    <TableCell align="right">Target</TableCell>
                    <TableCell align="right">Volume</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reagent.solutes.map(solute => (
                    <TableRow key={solute.name}>
                      <TableCell component="th" scope="row">
                        {`${solute.name} @\u00A0${formatVolumeObj(
                          getConcentration(
                            stockConcentrations,
                            solute.name,
                            solute.concentration.unit,
                          )!.concentration,
                        )}`}
                      </TableCell>
                      <TableCell align="right">
                        {formatVolumeObj(solute.concentration)}
                      </TableCell>
                      <TableCell align="right">
                        {formatVolumeObj(requiredVolumes.get(solute)!)}
                      </TableCell>
                    </TableRow>
                  ))}
                  {remainingVolume > 0.0 && (
                    <TableRow key="_diluent">
                      <TableCell component="th" scope="row">
                        {reagent.solutes.length ? 'Diluent' : reagent.name}
                      </TableCell>
                      <TableCell align="right" />
                      <TableCell align="right">
                        {formatVolume(remainingVolume, 'ul')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </>
          )}

          {reagent.tags?.length > 0 && (
            <>
              <Typography variant="subtitle2">Metadata</Typography>
              <Table size="small" aria-label="contents" className={classes.soluteTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>Label</TableCell>
                    <TableCell align="right">Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reagent.tags.map(tag => (
                    <TableRow key={tag.label}>
                      <TableCell component="th" scope="row">
                        {tag.label}
                      </TableCell>
                      <TableCell align="right">
                        {tag.valueFloat ?? (tag.valueString || 'n/a')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

/**
 * returns a map whose keys are the required solutes and values are the required
 * volumes given the available stocks
 */
function getRequiredSoluteVolumes(
  stockConcentrations: StockConcentration[],
  reagent: Reagent,
): Map<StockConcentration, Measurement> {
  return reagent.solutes?.reduce(function (map, solute) {
    const stock = getConcentration(
      stockConcentrations,
      solute.name,
      solute.concentration.unit,
    )!;
    const required = {
      value: reagent.volumeUl * divide(solute.concentration, stock.concentration),
      unit: 'ul',
    };
    return map.set(solute, required);
  }, new Map<StockConcentration, Measurement>());
}

/**
 * returns a map whose keys are the required stocks and values are the volume of
 * that stock
 */
function getRequiredStockVolumes(
  stockConcentrations: StockConcentration[],
  reagent: Reagent,
): Map<StockConcentration, Measurement> {
  return reagent.solutes?.reduce(function (map, solute) {
    const stock = getConcentration(
      stockConcentrations,
      solute.name,
      solute.concentration.unit,
    )!;
    const required = {
      value: reagent.volumeUl * divide(solute.concentration, stock.concentration),
      unit: 'ul',
    };
    return map.set(stock, required);
  }, new Map<StockConcentration, Measurement>());
}

type StockViewProps = {
  stockConcentrations: StockConcentration[];
  onUpdateStock: (key: string, value: Measurement) => void;
  reagents: Reagent[];
};

function StockView({ stockConcentrations, onUpdateStock, reagents }: StockViewProps) {
  const totalVolumes = reagents.reduce((totals, reagent) => {
    getRequiredStockVolumes(stockConcentrations, reagent).forEach((value, stock) => {
      const volume = totals.get(stock) ?? { value: 0.0, unit: 'ul' };
      volume.value += value.value;
      totals.set(stock, volume);
    });
    return totals;
  }, new Map<StockConcentration, Measurement>());

  // there are two ways that stock concentrations can be valid:

  // 1. The concentration of the stock is less than the concentration required
  //    by one or more reagents.
  //    This is an obvious error with only one way to fix it (increse the
  //    affected stock concentration) so highlighted
  const stockErrors = stockConcentrations.reduce((errs, stock) => {
    const minimum = reagents.reduce(
      (max, reagent) => {
        return reagent.solutes.reduce((max, solute) => {
          if (
            solute.name !== stock.name ||
            !isCompatible(solute.concentration.unit, stock.concentration.unit) ||
            greaterThanEqual(max, solute.concentration)
          ) {
            return max;
          }
          return solute.concentration;
        }, max);
      },
      { value: 0.0, unit: '' },
    );
    if (greaterThan(minimum, stock.concentration)) {
      errs.set(stock, 'higher concentration required');
    }
    return errs;
  }, new Map<StockConcentration, string>());

  // 2. All stocks are more concentrated than the required reagents, but
  //    at least one reagent where the sum of required volumes for each stock
  //    exceeds the volume of the reagent.
  //    Such an issue can be fixed by increasing the concentration of any of
  //    the affected stocks, or all of them in combination. Since this is a
  //    more subtle error, we only show them if there's no type 1 errors and
  //    we use a less agressive visual style.
  const stockWarnings =
    stockErrors.size > 0
      ? new Map<StockConcentration, string>()
      : reagents.reduce((errs, reagent) => {
          const requiredVolumes = getRequiredStockVolumes(stockConcentrations, reagent);
          // TODO: convert vol to ul rather than assume
          const stocksVolume = [...requiredVolumes.values()].reduce(
            (total, vol) => total + vol.value,
            0.0,
          );
          if (stocksVolume > reagent.volumeUl) {
            requiredVolumes.forEach((value, key) => {
              errs.set(key, 'higher concentration required');
            });
          }
          return errs;
        }, new Map<StockConcentration, string>());

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Stock</TableCell>
          <TableCell align="right">
            Concentration <br /> (Volume Required)
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {stockConcentrations.map(stock => (
          <TableRow key={`${stock.name}_${stock.concentration.unit}`}>
            <TableCell component="th" scope="row">
              {stock.name}
            </TableCell>
            <TableCell align="right">
              <div>
                <StockInput
                  concentration={stock.concentration}
                  onChange={concentration => onUpdateStock(stock.name, concentration)}
                  errorText={stockErrors.get(stock) || ''}
                  warningText={stockWarnings.get(stock) || ''}
                />
                <Typography>
                  (
                  {formatVolumeObj(totalVolumes.get(stock) || { value: 0.0, unit: 'ul' })}
                  )
                </Typography>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

type StockInputProps = {
  concentration: Measurement;
  errorText: string;
  warningText: string;
  onChange: (a: Measurement) => void;
};

function StockInput({
  concentration,
  errorText,
  warningText,
  onChange,
}: StockInputProps) {
  const classes = useStyles();
  return (
    <TextField
      size="small"
      style={{ width: '150px' }}
      error={errorText !== ''}
      className={cx({
        [classes.stockWarning]: warningText !== '',
      })}
      helperText={errorText || warningText}
      type="number"
      value={concentration.value}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{concentration.unit}</InputAdornment>
        ),
      }}
      onChange={evt =>
        onChange({ value: parseFloat(evt.target.value), unit: concentration.unit })
      }
    />
  );
}

function getDefaultStockConcentrations(reagents: Reagent[]): StockConcentration[] {
  // we calculate initial stock concentrations by calculating the stock
  // concentrations for each reagent assuming each stock takes up an equal
  // volume. Then we find the maximum required concentration of each stock
  // across all reagents.
  // This gives us a set of stock concentrations that are guaranteed to be
  // able to make all reagents.
  const concentrations: StockConcentration[] = [];
  reagents.forEach(r => {
    r.solutes?.forEach(s => {
      const requiredConc = {
        value: s.concentration.value * r.solutes.length,
        unit: s.concentration.unit,
      };

      const current = getConcentration(concentrations, s.name, s.concentration.unit);
      if (current === undefined) {
        concentrations.push({ name: s.name, concentration: requiredConc });
      } else if (lessThan(current.concentration, requiredConc)) {
        current.concentration = requiredConc;
      }
    });
  });

  concentrations.sort(byName);
  return concentrations;
}

const useStyles = makeStylesHook(({ palette, spacing }) => ({
  box: {
    padding: spacing(3),
    margin: spacing(3),
  },
  option: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: spacing(2),
    marginBottom: spacing(3),
  },
  reagentTable: {
    padding: spacing(3),
  },
  heading: {
    flexBasis: '33.33%',
    flexShrink: 0,
    flexGrow: 1,
  },
  secondaryHeading: {
    color: palette.text.secondary,
    fontStyle: 'italic',
    flexGrow: 1,
    flexShrink: 1,
  },
  volumeHeading: {
    color: palette.text.secondary,
    flexShrink: 0,
    marginRight: spacing(3),
  },
  soluteTable: {
    marginBottom: spacing(5),
  },
  reagentSummary: {
    flexDirection: 'row-reverse',
  },
  tabs: {
    backgroundColor: palette.background.paper,
    borderBottom: `1px solid ${palette.grey}`,
    paddingLeft: spacing(6),
  },
  expanded: {},
  stockWarning: {
    '& .MuiInput-underline:after': {
      borderBottomColor: palette.warning.dark,
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: palette.warning.main,
    },
    '& .MuiFormHelperText-root': {
      color: palette.warning.main,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: palette.warning.main,
    },
  },
  extraVolume: {
    width: '20ch',
    '& input': {
      textAlign: 'right',
    },
  },
  icon: {
    color: palette.text.primary,
  },
}));
