import React from 'react';
import { useMemo } from 'react';

import { styled } from '@mui/material/styles';

import { GroupsByStep } from 'client/app/apps/simulation-details/instructions/types';
import { Step } from 'common/types/steps';
import Colors from 'common/ui/Colors';
import { useTrackActive } from 'common/ui/components/TrackActive';

type TOCNode = { id: number; groupId?: number; name: string; steps: Step[] };

type Props = { className?: string; steps: Step[]; groups: GroupsByStep };

export function TableOfContents({ steps, groups, className }: Props) {
  const grouped = useMemo(() => {
    const result: TOCNode[] = [];
    let current: TOCNode | null = null;
    let id = 1;

    for (const step of steps) {
      const group = groups[step.id]?.group;
      const element = groups[step.id]?.element;

      if (!current || current.groupId !== group?.id) {
        current = {
          id: id++,
          groupId: group?.id,
          name: group?.name ?? '',
          steps: [],
        };

        result.push(current);
      }

      current?.steps.push({ ...step, name: element?.name ?? step.name });
    }

    return result;
  }, [groups, steps]);

  return (
    <ContentsWrapper className={className}>
      <TOCItems>
        {grouped.map(group =>
          group.groupId !== undefined ? (
            <Group key={group.id}>
              <GroupName>{group.name}</GroupName>
              <TOCSteps steps={group.steps} />
            </Group>
          ) : (
            <TOCSteps key={group.id} steps={group.steps} />
          ),
        )}
      </TOCItems>
    </ContentsWrapper>
  );
}

function TOCSteps({ steps }: { steps: Step[] }) {
  const { activeId, showById } = useTrackActive();

  return (
    <StepList>
      {steps?.map(step => (
        <StepListItem
          key={step.id}
          active={step.id === activeId}
          onClick={() => {
            if (step.id) {
              showById(step.id);
            }
          }}
        >
          {step.name}
        </StepListItem>
      ))}
    </StepList>
  );
}

const ContentsWrapper = styled('nav')(({ theme: { typography, spacing } }) => ({
  position: 'relative',
  overflow: 'auto',
  padding: spacing(6, 4, 6, 6),
  ...typography.body1,
}));

const StepList = styled('ol')({
  padding: 0,
  margin: 0,
  listStylePosition: 'inside',
});

const TOCItems = styled('div')(({ theme: { spacing } }) => ({
  width: '15vw',
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(6),
  counterReset: 'toc',
}));

const StepListItem = styled('li')<{ active: boolean }>(
  ({ theme: { spacing, palette }, active }) => ({
    listStyleType: 'none',
    counterIncrement: 'toc',
    padding: spacing(2, 3),
    ...(active
      ? {
          background: Colors.BLUE_5,
          color: palette.text.primary,
          borderLeft: `4px solid ${Colors.BLUE_10}`,
        }
      : {
          color: palette.text.secondary,
          cursor: 'pointer',
          borderLeft: `4px solid ${Colors.BLUE_5}`,
        }),
    '&::before': {
      content: `counter(toc) "."`,
      marginRight: spacing(2),
    },
    '&:hover': {
      color: palette.text.primary,
      borderLeftColor: Colors.BLUE_20,
    },
  }),
);

const Group = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(3),
}));

const GroupName = styled('div')(({ theme: { typography, palette } }) => ({
  ...typography.subtitle2,
  color: palette.text.primary,
  textWrap: 'balance',
}));
