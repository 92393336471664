import React, { Fragment, ReactNode, useMemo } from 'react';

import { styled } from '@mui/material/styles';

import { formatVolumeObj, formatWellColumn, formatWellRow } from 'common/lib/format';
import { Measurement } from 'common/types/mix';
import { PlateType } from 'common/types/plateType';
import { LayerPlate, Well, WellState } from 'common/types/steps';
import Colors from 'common/ui/Colors';
import { PERCEPTUAL_PALETTE } from 'common/ui/Colors';

export default function PlateMapGrid({
  content,
  plate,
  className,
  zoom,
  decorators,
  getWellState,
  getContextValue,
  showSource,
}: {
  content: LayerPlate;
  plate: PlateType;
  className?: string;
  zoom: number;
  decorators?: ReactNode;
  getWellState: (well: Well) => WellState | undefined;
  getContextValue?: (well: Well) => Measurement | undefined;
  showSource?: (well: Well) => boolean;
}) {
  const columns = useMemo(
    () => Array.from({ length: plate.columns }, (_, index) => formatWellColumn(index)),
    [plate.columns],
  );

  const rows = useMemo(
    () => Array.from({ length: plate.rows }, (_, index) => formatWellRow(index)),
    [plate.rows],
  );

  const liquidColors = useLiquidColors(content.wells, getWellState);

  return (
    <Grid
      // We use style instead of sx throughout this file because the latter creates
      // a new CSS class for each unique combination of values and the result is a huge
      // explosion of classes for every single grid cell due to the x/y properties.
      style={{
        '--colCount': columns.length,
        '--rowCount': rows.length,
        fontSize: 12 * zoom,
      }}
      className={className}
    >
      <TopLeft />
      {columns.map((c, i) => (
        <ColHeader key={c} style={{ '--x': i + 2, '--y': 1 }}>
          {c}
        </ColHeader>
      ))}
      {rows.map((rowLabel, rowIndex) => (
        <Fragment key={rowLabel}>
          <RowHeader style={{ '--x': 1, '--y': rowIndex + 2 }}>{rowLabel}</RowHeader>
          {columns.map((colLabel, colIndex) => {
            const well = content.wells[`${rowLabel}${colLabel}`];
            const wellState = well ? getWellState(well) : undefined;

            return wellState ? (
              <Content
                key={colLabel}
                wellState={wellState}
                contextValue={getContextValue?.(well)}
                // Grids are indexed from 1 while arrays are from 0.
                // We also need to consider the header row/column, hence +2
                x={colIndex + 2}
                y={rowIndex + 2}
                color={liquidColors(wellState.name)}
                source={showSource?.(well) ? well.source?.well : undefined}
              />
            ) : (
              <Cell style={{ '--x': colIndex + 2, '--y': rowIndex + 2 }} />
            );
          })}
        </Fragment>
      ))}
      {decorators}
    </Grid>
  );
}

export function GridDecorator({
  x,
  y,
  width,
  height,
  className,
  children,
}: {
  x: number;
  y: number;
  width: number;
  height: number;
  className?: string;
  children?: ReactNode;
}) {
  return (
    <DecoratorContent
      className={className}
      style={{ '--x': x + 1, '--y': y + 1, '--width': width, '--height': height }}
    >
      {children}
    </DecoratorContent>
  );
}

function Content({
  x,
  y,
  wellState,
  contextValue,
  color,
  source,
}: {
  x: number;
  y: number;
  wellState: WellState;
  contextValue?: Measurement;
  color: string;
  source?: string;
}) {
  return (
    <FilledCell style={{ '--x': x, '--y': y, background: color }}>
      <FilledCellContent>
        <NameValue>{wellState.name}</NameValue>
        {contextValue ? (
          <Concentration>({formatVolumeObj(contextValue)} )</Concentration>
        ) : null}
        <Volume>{formatVolumeObj(wellState.volume)}</Volume>
        {source && (
          <FromLabel>
            From <span className="coord">{source}</span>
          </FromLabel>
        )}
      </FilledCellContent>
    </FilledCell>
  );
}

function useLiquidColors(
  wells: Record<string, Well>,
  getWellState: (well: Well) => WellState | undefined,
) {
  return useMemo(() => {
    const uniqueNames = new Set(
      Object.values(wells)
        .map(getWellState)
        .flatMap(well => (well ? [well.name] : [])),
    );

    const names = [...uniqueNames].sort();

    const lightenedPalette = Object.values(PERCEPTUAL_PALETTE).map(v => `${v}40`);
    const defaultColor = lightenedPalette[0];

    // We don't currently bother trying to do graph-based colors like the preview, we only
    // show liquid colors if the number of unique liquid names on the plate is less than
    // the number of colors available in the palette.
    if (uniqueNames.size <= lightenedPalette.length) {
      const map = new Map(names.map((name, index) => [name, lightenedPalette[index]]));

      return (name: string) => map.get(name) ?? defaultColor;
    }

    return () => defaultColor;
  }, [getWellState, wells]);
}

const Header = styled('div')(({ theme: { palette, typography } }) => ({
  gridColumn: 'var(--x)',
  gridRow: 'var(--y)',
  background: palette.grey[200],
  padding: '0.5em',
  position: 'sticky',
  ...typography.subtitle1,
  lineHeight: '1.5em',
  fontSize: 'inherit',
  display: 'grid',
  placeItems: 'center',
  borderColor: 'rgba(0, 0, 0, 0.15)',
  borderStyle: 'solid',
  borderWidth: '0 1px 1px 0',
}));

const TopLeft = styled(Header)({
  top: 0,
  left: 0,
  zIndex: 2,
});

const ColHeader = styled(Header)({
  top: 0,
});

const RowHeader = styled(Header)({
  left: 0,
});

const Cell = styled('div')({
  gridColumn: 'var(--x)',
  gridRow: 'var(--y)',
  padding: '0.5em',
  minWidth: '7em',
  textAlign: 'center',
  borderColor: 'rgba(0, 0, 0, 0.15)',
  borderStyle: 'solid',
  borderWidth: '0 1px 1px 0',
});

const FilledCell = styled(Cell)({
  background: Colors.BLUE_0,
});

const FilledCellContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.2em',
});

const NameValue = styled('span')(({ theme: { typography } }) => ({
  ...typography.subtitle2,
  lineHeight: '1.5em',
  fontSize: '1.2em',
}));

const FromLabel = styled('span')(({ theme: { typography } }) => ({
  ...typography.body2,
  lineHeight: '1.5em',
  ' .coord': {
    fontWeight: 600,
  },
}));

const Volume = styled('span')(({ theme: { typography } }) => ({
  ...typography.body2,
  lineHeight: '1.5em',
  fontSize: 'inherit',
}));

const Concentration = styled('span')(({ theme: { typography } }) => ({
  ...typography.body2,
  lineHeight: '1.5em',
  fontSize: 'inherit',
  fontStyle: 'italic',
}));

const Grid = styled('div')({
  width: 'fit-content',
  display: 'grid',
  gridTemplateColumns: `minmax(auto, 60px) repeat(var(--colCount), max-content)`,
  gridTemplateRows: `auto repeat(var(--rowCount) auto)`,
});

const DecoratorContent = styled('div')({
  gridRow: `var(--y) / span var(--height)`,
  gridColumn: `var(--x) / span var(--width)`,
  zIndex: 1,
  position: 'relative',
});
