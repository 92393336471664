import { useMemo } from 'react';
import React from 'react';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import UIErrorBox from 'client/app/components/UIErrorBox';
import { reportError } from 'client/app/lib/errors';
import { formatVolumeObj } from 'common/lib/format';
import { Measurement } from 'common/types/mix';
import { Step } from 'common/types/steps';

type Props = { step: Step };

type Row = {
  id: string;
  liquid: string;
  liquidConcentration: Measurement;
  liquidVolume: Measurement;
  finalConcentration: Measurement;
  finalVolume: Measurement;
  diluent: string;
  diluentVolume: Measurement;
};

const EMPTY_MEASUREMENT: Measurement = { value: 0, unit: '' };

function useRows(step: Step): [Row[], null] | [null, string] {
  return useMemo(() => {
    const rowsByWell = new Map<string, Row>();

    if (step.layers.length === 1) {
      // This is dilution in place. Liquids to dilute are already in the wells, then diluents are added.
      for (const [plateName, plate] of Object.entries(step.layers[0].plates)) {
        for (const [well, entry] of Object.entries(plate.wells)) {
          const id = `${plateName}-${well}`;

          if (
            !entry.initial?.concentration ||
            !entry.final.concentration ||
            !entry.added
          ) {
            const error = `Invalid well was provided for in-place dilution: ${JSON.stringify(
              step,
            )}`;
            reportError(new Error(error));

            return [null, error];
          }

          rowsByWell.set(well, {
            id,
            liquid: entry.initial.name ?? 'Unknown',
            liquidVolume: entry.initial.volume,
            liquidConcentration: entry.initial.concentration,
            finalConcentration: entry.final.concentration,
            finalVolume: entry.final.volume,
            diluent: entry.added.name,
            diluentVolume: entry.added.volume,
          });
        }
      }
    } else if (step.layers.length === 2) {
      // This is dilution to a new location. Dilulents are added first, then liquids to dilute.
      // First layer is the diluents.
      for (const [plateName, plate] of Object.entries(step.layers[0].plates)) {
        for (const [well, entry] of Object.entries(plate.wells)) {
          const id = `${plateName}-${well}`;

          if (!entry.added) {
            const error = `Invalid well was provided for dilution to new location: ${JSON.stringify(
              step,
            )}`;
            reportError(new Error(error));

            return [null, error];
          }

          rowsByWell.set(id, {
            id,
            liquid: '',
            liquidVolume: EMPTY_MEASUREMENT,
            liquidConcentration: EMPTY_MEASUREMENT,
            finalConcentration: EMPTY_MEASUREMENT,
            finalVolume: EMPTY_MEASUREMENT,
            diluent: entry.added.name,
            diluentVolume: entry.added.volume,
          });
        }
      }

      // Second layer is the liquids to mix
      for (const [plateName, plate] of Object.entries(step.layers[1].plates)) {
        for (const [well, entry] of Object.entries(plate.wells)) {
          const id = `${plateName}-${well}`;
          const row = rowsByWell.get(id);

          if (!entry.added?.concentration || !entry.final.concentration) {
            const error = `Invalid well was provided for dilution to new location: ${JSON.stringify(
              step,
            )}`;
            reportError(new Error(error));

            return [null, error];
          }

          if (row) {
            row.liquid = entry.added.name;
            row.liquidVolume = entry.added.volume;
            row.liquidConcentration = entry.added.concentration;
            row.finalConcentration = entry.final.concentration;
            row.finalVolume = entry.final.volume;
          }
        }
      }
    } else {
      // Shouldn't happen.
      const error = `Step for dilution table had more than two layers: ${JSON.stringify(
        step,
      )}`;
      reportError(new Error(error));
      return [null, error];
    }

    return [Array.from(rowsByWell.values()), null];
  }, [step]);
}

export default function DilutionTable({ step }: Props) {
  const [rows, error] = useRows(step);

  if (error || !rows) {
    return (
      <UIErrorBox>An error was encountered while creating the dilution table.</UIErrorBox>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Liquid</TableCell>
            <TableCell align="right">Volume</TableCell>
            <TableCell align="right">Concentration</TableCell>
            <TableCell align="right">Diluent Volume</TableCell>
            <TableCell align="right">Final Volume</TableCell>
            <TableCell align="right">Final Concentration</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <StyledRow key={row.id}>
              <TableCell>{row.liquid}</TableCell>
              <TableCell align="right">{formatVolumeObj(row.liquidVolume)}</TableCell>
              <TableCell align="right">
                {formatVolumeObj(row.liquidConcentration)}
              </TableCell>
              <TableCell align="right">{formatVolumeObj(row.diluentVolume)}</TableCell>
              <TableCell align="right">{formatVolumeObj(row.finalVolume)}</TableCell>
              <TableCell align="right">
                {formatVolumeObj(row.finalConcentration)}
              </TableCell>
            </StyledRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const StyledRow = styled(TableRow)({
  '&:last-child td, &:last-child th': { border: 0 },
});
