import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { QUERY_BREADCRUMBS } from 'client/app/api/gql/queries';
import CurrentEntityProvider from 'client/app/components/nav/breadcrumbs/BreadcrumbsEntityContext';
import NavNode, {
  CollapsedNode,
  NodeSkeleton,
  RootNode,
} from 'client/app/components/nav/breadcrumbs/components/NavNode';
import {
  type NavNode as NavNodeType,
  BreadcrumbsPayload,
} from 'client/app/components/nav/breadcrumbs/types';
import useBreadcrumbsEntity from 'client/app/components/nav/breadcrumbs/useBreadcrumbsEntity';
import useCurrentScreenEntity from 'client/app/components/nav/breadcrumbs/useCurrentScreenEntity';
import DocsButton from 'client/app/components/nav/DocsButton';
import IntercomButton from 'client/app/components/nav/IntercomButton';
import UserDetailsMenu from 'client/app/components/nav/UserDetailsMenu';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import SynthaceBrandmarkLight from 'common/assets/SynthaceBrandmarkLight';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { getEnvColour, getEnvName } from 'common/lib/envs';
import Colors from 'common/ui/Colors';
import { TOP_NAV_HEIGHT } from 'common/ui/components/TopNav/topNavStyles';

const NO_NODES = [] as BreadcrumbsPayload;

function BreadcrumbsHeader() {
  const isShowColorLogo = useFeatureToggle('SHOW_COLOR_LOGO');

  const envName = getEnvName(window.location?.host);
  const envColour = isShowColorLogo ? 'multicolor' : getEnvColour(envName);

  const userProfile = useUserProfile();

  const currentScreenEntity = useCurrentScreenEntity();

  const currentEntity = useBreadcrumbsEntity({
    entity: currentScreenEntity,
  } as NavNodeType);

  const { data, loading } = useQuery(QUERY_BREADCRUMBS, {
    variables: {
      id: currentEntity.id,
      entity: currentScreenEntity,
    },
    skip: !currentEntity.id,
    fetchPolicy: 'network-only',
  });
  const navNodes = data?.breadcrumbs ?? NO_NODES;

  const isNarrowWidth = useMediaQuery(`(max-width:900px)`);
  const showCollapsedHeader = loading
    ? isNarrowWidth
    : isNarrowWidth && navNodes.length > 3;

  const placeholders = useMemo(() => {
    return showCollapsedHeader
      ? Array.from({ length: 3 })
      : Array.from({ length: currentEntity.level });
  }, [currentEntity.level, showCollapsedHeader]);

  const nodesToRender = useMemo(() => {
    if (loading) {
      return placeholders.map((_, index) => (
        <NodeSkeleton key={index} expanded={index === placeholders.length - 1} />
      ));
    }
    if (!showCollapsedHeader) {
      return navNodes.map((node, index) => (
        <NavNode key={index} node={node} expanded={index === navNodes.length - 1} />
      ));
    }

    const nodesToCollapse = navNodes.slice(1, navNodes.length - 1);
    const firstNode = navNodes[0];
    const lastNode = navNodes[navNodes.length - 1];

    return [
      <NavNode key={0} node={firstNode} />,
      <CollapsedNode key={1} nodes={nodesToCollapse} />,
      <NavNode key={navNodes.length - 1} node={lastNode} expanded />,
    ];
  }, [loading, navNodes, placeholders, showCollapsedHeader]);

  return (
    <Container>
      <Nav data-heap-tracking="breadcrumbs-header-nav">
        <RootNode>
          <StyledSynthaceBrandmark logoColour={envColour} />
        </RootNode>
        <CurrentEntityProvider currentEntity={currentScreenEntity}>
          {nodesToRender}
        </CurrentEntityProvider>
      </Nav>
      {userProfile && (
        <RightSection>
          <IntercomButton userProfile={userProfile} />
          <DocsButton />
          <UserDetailsMenu userProfile={userProfile} useAvatar />
        </RightSection>
      )}
    </Container>
  );
}

export default React.memo(BreadcrumbsHeader);

const Container = styled(Paper)`
  display: grid;
  grid-template-columns: 1fr min-content;
  min-height: ${TOP_NAV_HEIGHT};
  max-height: ${TOP_NAV_HEIGHT};

  background-color: ${Colors.GREY_0};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
  z-index: 2;
  border-bottom: 1px solid ${Colors.GREY_30};
`;

const Nav = styled('nav')`
  display: flex;
  align-items: stretch;
  overflow: hidden;
`;

const RightSection = styled('section')`
  display: flex;
  align-items: center;
  color: ${Colors.TEXT_PRIMARY};
`;

const StyledSynthaceBrandmark = styled(SynthaceBrandmarkLight)`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;
