import React from 'react';

import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import ActiveDot from 'client/app/components/nav/breadcrumbs/components/ActiveDot';
import SubItemContainer from 'client/app/components/nav/breadcrumbs/components/SubItemContainer';
import {
  type ExecutionEntityItem,
  NavNodeSubItem,
} from 'client/app/components/nav/breadcrumbs/types';

type Props = {
  isActive: boolean;
  isInput?: boolean;
  item: NavNodeSubItem;
  icon: React.ReactElement;
  onClick: React.MouseEventHandler<HTMLElement>;
};

export default function ExecutionEntityItem({
  item,
  isActive,
  isInput,
  icon,
  onClick,
}: Props) {
  const execution = item as ExecutionEntityItem;
  const timestamp = moment(execution.createdAt).format('YYYY-MM-DD HH:mm');

  return (
    <Container data-heap-tracking="breadcrumbs-execution-entity-item" onClick={onClick}>
      {isActive ? <ActiveDot /> : <span />}
      {icon}
      <Stack gap={1}>
        <Typography variant="subtitle2" color="textPrimary">
          {execution.name}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {timestamp}
        </Typography>
      </Stack>
      {isInput ? <LinkIcon /> : <span />}
    </Container>
  );
}

const Container = styled(SubItemContainer)`
  grid-template-columns: 5px 24px 1fr 16px;
`;

const LinkIcon = styled(InsertLinkOutlinedIcon)`
  font-size: 16px;
`;
