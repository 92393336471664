import { useCallback } from 'react';

import { ApolloCache, useMutation } from '@apollo/client';

import { MUTATION_RENAME_BREADCRUMBS_ENTITY } from 'client/app/api/gql/mutations';
import { QUERY_BREADCRUMBS } from 'client/app/api/gql/queries';
import { BreadcrumbsEntityEnum } from 'client/app/gql';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export default function useRenameEntity() {
  const { showError } = useSnackbarManager();

  const [renameMutation] = useMutation(MUTATION_RENAME_BREADCRUMBS_ENTITY);
  return useCallback(
    (
        id: string,
        entity: BreadcrumbsEntityEnum,
        onRenameSuccess?: (newName: string) => void,
      ) =>
      async (newName: string) => {
        try {
          const { errors } = await renameMutation({
            variables: {
              id,
              entity,
              newName,
            },
            update: renameEntityInCache(id, entity, newName),
          });
          if (!errors) {
            onRenameSuccess?.(newName);
          } else {
            errors.forEach(error => {
              showError(error.message);
            });
          }
        } catch {
          showError(`Could not rename ${entity}`);
        }
      },
    [renameMutation, showError],
  );
}

function renameEntityInCache(id: string, entity: BreadcrumbsEntityEnum, newName: string) {
  return function update(cache: ApolloCache<any>) {
    cache.updateQuery(
      {
        query: QUERY_BREADCRUMBS,
        variables: { id, entity },
      },
      data =>
        !data
          ? data
          : {
              ...data,
              breadcrumbs: data.breadcrumbs.map(crumb =>
                crumb.entity === entity
                  ? {
                      ...crumb,
                      items: crumb.items.map(item =>
                        item.id === id ? { ...item, name: newName } : item,
                      ),
                    }
                  : crumb,
              ),
            },
    );
  };
}
