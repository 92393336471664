import React, { useMemo } from 'react';

import { styled } from '@mui/material/styles';

import Instructions from 'client/app/apps/simulation-details/instructions/Instructions';
import { TableOfContents } from 'client/app/apps/simulation-details/instructions/TableOfContents';
import { GroupsByStep } from 'client/app/apps/simulation-details/instructions/types';
import { StepsJson } from 'common/types/steps';
import { TrackActiveProvider } from 'common/ui/components/TrackActive';

type Props = {
  instructions: StepsJson;
};

export default function InstructionsScreen({ instructions }: Props) {
  const groups = useMemo(() => {
    const result: GroupsByStep = {};

    instructions.steps.forEach(step => {
      step.groups.forEach(id => {
        const group = instructions.groups[id];

        if ('groupID' in group.meta) {
          result[step.id] = { ...result[step.id], group: { ...(group as any), id } };
        } else if ('elementID' in group.meta) {
          result[step.id] = { ...result[step.id], element: { ...(group as any), id } };
        }
      });
    });

    return result;
  }, [instructions.groups, instructions.steps]);

  return (
    <TrackActiveProvider>
      <Wrapper>
        <TableOfContents steps={instructions.steps} groups={groups} />
        <Instructions steps={instructions.steps} groups={groups} />
      </Wrapper>
    </TrackActiveProvider>
  );
}

const Wrapper = styled('div')({
  overflow: 'auto',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gridTemplateRows: 'auto',
  placeItems: 'stretch',
});
