import { useMemo } from 'react';

import {
  FactorKind,
  FactorParameterInfo,
} from 'client/app/components/DOEFactorForm/types';
import { getBasicFactorType } from 'client/app/components/DOEFactorForm/useDOEFactorForm';
import { EditorType } from 'common/elementConfiguration/EditorType';
import {
  getKeyTypeFromAnthaType,
  getValueTypeFromAnthaType,
} from 'common/elementConfiguration/parameterUtils';
import { FactorItem, Parameter } from 'common/types/bundle';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';

const SHOW_MORE_LABEL_WIDTH = 60;
const LEVEL_MARGIN = 8;
const PANEL_TRANSITION = 200;

/**
 * Examine the configuration of an element parameter to figure out what kind of
 * factor(s) can be assigned to it. Only double-maps support multiple factors.
 *
 * This info is used in the panel to determine whether to enable the add factor button
 * and inside the factor details panel to control which editors are shown.
 */
function getEditorInfo(param?: Parameter) {
  let result: {
    allowMultipleFactors: boolean;
    keyType?: string;
    valueType?: string;
    keyEditor?: ParameterEditorConfigurationSpec;
    valueEditor?: ParameterEditorConfigurationSpec;
  };
  const editor = param?.configuration?.editor;

  if (!editor) {
    return { allowMultipleFactors: false };
  }

  const valueType = getValueTypeFromAnthaType(param.type);

  if (
    editor.type === EditorType.MAP &&
    editor.additionalProps?.editor === EditorType.MAP
  ) {
    const nestedEditor = editor.additionalProps.valueEditor;

    result =
      nestedEditor?.type === EditorType.MAP &&
      nestedEditor?.additionalProps?.editor === EditorType.MAP
        ? {
            keyType: getKeyTypeFromAnthaType(valueType),
            keyEditor: nestedEditor.additionalProps.keyEditor,
            valueEditor: nestedEditor.additionalProps.valueEditor,
            allowMultipleFactors: true,
          }
        : {
            valueEditor: editor.additionalProps.valueEditor,
            allowMultipleFactors: false,
            valueType,
          };
  } else if (
    editor.type === EditorType.ARRAY &&
    editor.additionalProps?.editor === EditorType.ARRAY
  ) {
    result = {
      valueEditor: editor.additionalProps.itemEditor,
      allowMultipleFactors: false,
      valueType,
    };
  } else {
    result = {
      valueEditor: editor,
      allowMultipleFactors: false,
      valueType,
    };
  }

  return result;
}

function getTypeCopy(factor: FactorItem) {
  switch (factor.variableTypeName) {
    case 'factor':
      return factor.values.length === 1 ? 'Constant' : 'Design';
    case 'derived':
      return 'Derived';
    case 'quasi-replicating':
      return 'Quasi Replicate';
    default:
      return factor.variableTypeName;
  }
}

export function getFactorName(factor: Pick<FactorItem, 'displayName' | 'path'>) {
  return !factor.path?.[1]
    ? factor.displayName
    : `${factor.displayName} (${factor.path[1]})`;
}

const isNumericFactor = (factor: Pick<FactorItem, 'typeName'>) =>
  factor.typeName === 'continuous' ||
  factor.typeName === 'discrete' ||
  factor.typeName === 'ordinal';

export function usePermittedFactorKinds(
  parameterInfo?: FactorParameterInfo,
  derivableFactors?: {
    all: FactorItem[] | undefined;
    numeric: FactorItem[] | undefined;
  },
): FactorKind[] {
  return useMemo(() => {
    const permitted: FactorKind[] = ['factor'];

    if (parameterInfo) {
      if (parameterInfo.allowMultipleFactors) {
        permitted.push('mutual-exclusion', 'constant');
      }

      const basicType = getBasicFactorType(parameterInfo);

      if (
        basicType === 'continuous'
          ? derivableFactors?.numeric?.length
          : derivableFactors?.all?.length
      ) {
        permitted.push('derived');
      }
    } else {
      permitted.push('derived', 'mutual-exclusion');
    }

    return permitted;
  }, [derivableFactors?.all?.length, derivableFactors?.numeric?.length, parameterInfo]);
}

export {
  SHOW_MORE_LABEL_WIDTH,
  LEVEL_MARGIN,
  PANEL_TRANSITION,
  getEditorInfo,
  getTypeCopy,
  isNumericFactor,
};
