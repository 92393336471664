import React from 'react';

import AccessTimeOutlined from '@mui/icons-material/AccessTimeOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { ExecutionStatusEnum } from 'client/app/gql';
import {
  formatShortTransitiveSimulationStatus,
  getExecutionStatusHelperText,
} from 'client/app/lib/formatTransitiveSimulationStatus';
import { switchFail } from 'common/assert';
import Colors from 'common/ui/Colors';
import Tooltip from 'common/ui/components/Tooltip';
import { LoadingIcon } from 'common/ui/icons/Loading';
import RunningWithErrorsIcon from 'common/ui/icons/RunningWithErrorsIcon';

function ExecutionStatusIndicator({ status }: { status: ExecutionStatusEnum }) {
  switch (status) {
    case ExecutionStatusEnum.EXECUTION_SUCCESS:
      return <CheckCircleOutlineOutlinedIcon htmlColor={Colors.SUCCESS_MAIN} />;
    case ExecutionStatusEnum.EXECUTION_COMPLETED:
      // This state indicates some steps were successful and some failed. Confusingly, in
      // the UI this is called 'incomplete'.
      return <RunningWithErrorsIcon htmlColor={Colors.ERROR} />;
    case ExecutionStatusEnum.EXECUTION_FAILED:
      return <ErrorOutlineOutlinedIcon htmlColor={Colors.ERROR} />;
    case ExecutionStatusEnum.EXECUTION_IN_PROGRESS:
      return <LoadingIcon />;
    case ExecutionStatusEnum.EXECUTION_SCHEDULED:
      return <AccessTimeOutlined />;
    default:
      switchFail(status);
      return null;
  }
}

export function ExecutionStatusIndicatorWithTooltip({
  status,
}: {
  status: ExecutionStatusEnum;
}) {
  return (
    <Tooltip
      title={
        <>
          <strong>{formatShortTransitiveSimulationStatus(status)}</strong>
          <br />
          {getExecutionStatusHelperText(status)}
        </>
      }
    >
      <div>
        <ExecutionStatusIndicator status={status} />
      </div>
    </Tooltip>
  );
}
