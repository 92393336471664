import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import omit from 'lodash/omit';

import {
  QUERY_ALL_DEVICES,
  QUERY_DEVICE_CONFIG_RESPONSE,
} from 'client/app/api/gql/queries';
import { PanelWithoutScroll } from 'client/app/apps/workflow-builder/panels/Panel';
import {
  getSelectedMainDevice,
  useGetDeviceCommonForWorkflow,
} from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/deckOptionsPanelUtils';
import DeviceLibrary from 'client/app/components/DeviceLibrary/DeviceLibrary';
import { AllDevicesQuery, DeviceCommonFragment as DeviceCommon } from 'client/app/gql';
import {
  buildDeviceConfigurationForUI,
  getDeviceConfigurationForUI,
  isBioReactor,
  isDataOnly,
  isLiquidHandlingDevice,
  removeMissingDeviceFromDeviceConfiguration,
} from 'client/app/lib/workflow/deviceConfigUtils';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { DATA_ONLY_DUMMY_DEVICE } from 'common/constants/manual-device';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { mapObject } from 'common/object';
import { WorkflowDeviceConfiguration } from 'common/types/bundle';
import Button from 'common/ui/components/Button';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type AllDevices = AllDevicesQuery['devices'];

type DeviceSelectorPanelProps = {
  onClose: () => void;
  className: string;
};

/**
 * Panel that allows you to search for devices + select device(s).
 */
export default React.memo(function DeviceSelectorPanel(props: DeviceSelectorPanelProps) {
  const classes = useStyles();
  const { onClose, className } = props;
  const dispatch = useWorkflowBuilderDispatch();
  const { loading, data, error, refetch } = useQuery(QUERY_ALL_DEVICES);
  const { devices, savedDevices, manualDevice } = useSavedDevices(data?.devices);

  const [selectedDevices, setSelectedDevices] =
    useState<WorkflowDeviceConfiguration>(savedDevices);
  const devicesCommon = useGetDeviceCommonForWorkflow(selectedDevices);
  const { deviceId } = getSelectedMainDevice(selectedDevices, devicesCommon);

  const [getParsedRunConfig] = useLazyQuery(QUERY_DEVICE_CONFIG_RESPONSE);
  const isDataOnlySelected = Reflect.has(selectedDevices, DATA_ONLY_DUMMY_DEVICE.id);
  const isManualSelected =
    !!manualDevice && Reflect.has(selectedDevices, manualDevice.id);

  // TODO make this useful by helping apollo caching the result (CI-1345).
  // For that we would need the DeviceRunConfig graphql response to provide an ID
  // (possibly the device id X runconfig ID as some devices have 0 run configs).
  // The benefit of this is that we preload the run config before click saving, so it is faster
  useEffect(() => {
    if (deviceId && !isDataOnlySelected && !isManualSelected) {
      void getParsedRunConfig({
        variables: { id: deviceId },
      });
    }
  }, [deviceId, getParsedRunConfig, isDataOnlySelected, isManualSelected]);

  const multi_device_allowed = useFeatureToggle('ALLOW_MULTI_LIQUID_HANDLER_DEVICES');
  const isDeviceDisabled = useCallback(
    (device: DeviceCommon) => {
      if (selectedDevices[device.id]) {
        return { disabled: false };
      }
      if (isDataOnlySelected || isManualSelected) {
        return { disabled: true };
      }
      if (isBioReactor(device)) {
        return {
          disabled: true,
          reason: 'This device cannot be used in workflow',
        };
      }
      const isLiquidHandlingDeviceSelected = devices.some(
        deviceToCheck =>
          selectedDevices[deviceToCheck.id] && isLiquidHandlingDevice(deviceToCheck),
      );
      const disabled =
        isLiquidHandlingDeviceSelected &&
        isLiquidHandlingDevice(device) &&
        !multi_device_allowed;
      return {
        disabled,
        reason: disabled
          ? 'Only one liquid handler can be selected at a time'
          : undefined,
      };
    },
    [
      devices,
      isDataOnlySelected,
      isManualSelected,
      multi_device_allowed,
      selectedDevices,
    ],
  );
  const handleSelect = useCallback(
    (id: string) => {
      const deviceInfo = devices.find(device => device.id === id);
      if (deviceInfo && isDeviceDisabled(deviceInfo).disabled) {
        return;
      }

      let updatedSelectedDevices: WorkflowDeviceConfiguration;
      if (!Reflect.has(selectedDevices, id)) {
        if (deviceInfo) {
          // Select automation device
          const selectedDeviceConfig = buildDeviceConfigurationForUI([deviceInfo]);
          updatedSelectedDevices = { ...selectedDeviceConfig, ...selectedDevices };
        } else {
          // Select Data-only and unselect all other devices
          updatedSelectedDevices = { [id]: { anthaLangDeviceClass: 'Data-only' } };
        }
      } else {
        // Unselect a device if the id matches.
        updatedSelectedDevices = omit(selectedDevices, id);
        // Unselect device from accessibleDevices
        updatedSelectedDevices = mapObject(
          updatedSelectedDevices,
          (_deviceId, devConf) => ({
            ...devConf,
            accessibleDeviceIds: devConf.accessibleDeviceIds?.filter(
              accessibleDeviceId => accessibleDeviceId !== id,
            ),
          }),
        );
      }
      setSelectedDevices(updatedSelectedDevices);
    },
    [devices, isDeviceDisabled, selectedDevices],
  );

  const handleClear = () => setSelectedDevices({});

  const apollo = useApolloClient();
  const handleSave = async () => {
    if (isDataOnlySelected) {
      dispatch({ type: 'setConfigToNoDevices' });
    } else if (isManualSelected) {
      /**
       * There is no runConfiguration available for Manual device at the time writing this.
       * Hence, we handle this case separately and not request for runConfig or advanced options.
       */
      dispatch({
        type: 'saveSelectedDevices',
        payload: {
          deviceConfiguration: selectedDevices,
          runConfiguration: undefined,
        },
      });
    } else {
      // We have to wait to get the apollo result before we can actually dispatch `saveSelectedDevices`.
      // This is to avoid dispatching the action and have a transient unstable state between those 2 actions (selected device but not yet config).
      // If we did not have magic default runConfig we wouldn't need to fetch runConfig and wait.
      // If we do not get any runConfigData back from the apollo query, we will set the devices only, and reset the
      // default values that would have come from the run config, until the user selects a new run config in the
      // DeckOptionsPanel.
      // TODO Consider if it is necessary to improve speed
      const runConfigData = await apollo
        .query({
          query: QUERY_DEVICE_CONFIG_RESPONSE,
          variables: { id: deviceId },
        })
        .catch(() => null); // Do not crash if no default.

      dispatch({
        type: 'saveSelectedDevices',
        payload: {
          deviceConfiguration: selectedDevices,
          runConfiguration: runConfigData?.data,
        },
      });
    }
  };

  const selectedDeviceIds = Object.keys(selectedDevices);

  let panelContent;
  if (error) {
    panelContent = <GraphQLErrorPanel error={error} onRetry={refetch} />;
  } else if (loading) {
    panelContent = <CircularProgress />;
  } else if (devices.length === 0) {
    panelContent = <Typography variant="h5"> No devices found.</Typography>;
  } else {
    panelContent = (
      <DeviceLibrary
        isLoading={loading}
        onSelect={handleSelect}
        devices={devices}
        selectedDeviceIds={selectedDeviceIds}
        isDeviceDisabled={isDeviceDisabled}
        showSelectionStatus
        showManualDeviceRelatedCards
        smallCard
        dialog
        clearSelectionProps={{
          selectedDeviceCount: selectedDeviceIds.length,
          totalDeviceCount: devices.length,
          onClear: handleClear,
        }}
      />
    );
  }

  return (
    <PanelWithoutScroll
      title="Execution Mode"
      className={className}
      onClose={onClose}
      panelContent="DeviceSelector"
      fullWidth
      panelActions={
        <div className={classes.actions}>
          <Button
            className={classes.rightAlign}
            onClick={handleSave}
            variant="tertiary"
            color="primary"
          >
            {selectedDeviceIds.length > 0 ? 'Next' : 'Save'}
          </Button>
        </div>
      }
    >
      {panelContent}
    </PanelWithoutScroll>
  );
});

const NO_DEVICES: AllDevices = [];

function useSavedDevices(allDevices: AllDevices | undefined) {
  const devices = allDevices ?? NO_DEVICES;
  const manualDevice = useMemo(
    () => devices.find(d => d.model.anthaLangDeviceClass === 'Manual'),
    [devices],
  );
  const savedDevices = useWorkflowBuilderSelector(state => {
    if (isDataOnly(state.config)) {
      return { [DATA_ONLY_DUMMY_DEVICE.id]: { anthaLangDeviceClass: 'Data-only' } };
    } else {
      return removeMissingDeviceFromDeviceConfiguration(
        getDeviceConfigurationForUI(state.config),
        devices,
      );
    }
  });
  return { devices, savedDevices, manualDevice };
}

const useStyles = makeStylesHook({
  actions: {
    display: 'flex',
  },
  rightAlign: {
    marginLeft: 'auto',
  },
});
