import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import PrintIcon from '@mui/icons-material/Print';
import clamp from 'lodash/clamp';
import debounce from 'lodash/debounce';
import ReactToPrint from 'react-to-print';

import { Plate, WellLocationOnDeckItem } from 'common/types/mix';
import { MixPreview } from 'common/types/mixPreview';
import {
  SIMULATION_DETAILS_SETUP_TAB_ID,
  SLIDER_LOG_DEBOUNCE_MS,
} from 'common/ui/AnalyticsConstants';
import Colors from 'common/ui/Colors';
import Fab from 'common/ui/components/Fab';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import {
  getDeckItemStates,
  getWellContents,
  platesOnly,
} from 'common/ui/components/simulation-details/mix/deckContents';
import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';
import MixStateCache from 'common/ui/components/simulation-details/mix/MixStateCache';
import RightPanel from 'common/ui/components/simulation-details/mix/RightPanel';
import PlatePrepScreenPrintable from 'common/ui/components/simulation-details/plate-prep/PlatePrepScreenPrintable';
import PlatesSidebar from 'common/ui/components/simulation-details/plate-prep/PlatesSidebar';
import PlateView from 'common/ui/components/simulation-details/plate-prep/PlateView';
import StepSlider, { KeyPoint } from 'common/ui/components/simulation-details/StepSlider';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';
import { RouteHiddenContext } from 'common/ui/lib/router/RouteHiddenContext';

export type PlatePrepProps = {
  mixPreview: MixPreview;
  /**
   * Displayed when users print the page
   */
  simulationName?: string;
  /**
   * Whether to display the FAB that allows users to print the page
   */
  showPrintButton?: boolean;
  /*
   * This component can be used as a dialog, to allow users to select a plate
   * from a scheduled simulation. In this case, we'll show a special `Setup` screen:
   * users are interested only in the resulting plate (i.e. the last step), thus we
   * default to that step and hide the slider.
   */
  dialogProps?: { handleSelectPlate: (plate: Plate | null) => void };
};

// On the plate prep screen, we show no key points
const NO_SLIDER_KEY_POINTS: readonly KeyPoint[] = [];

const debouncedSliderChangeEventLog = debounce((stepNumber: number) => {
  logEvent('move-step-slider', SIMULATION_DETAILS_SETUP_TAB_ID, `${stepNumber}`);
}, SLIDER_LOG_DEBOUNCE_MS);

const logPrintEvent = () => {
  logEvent('print-plate-prep-screen', SIMULATION_DETAILS_SETUP_TAB_ID);
};

function PlatePrepScreen({
  mixPreview,
  simulationName,
  showPrintButton,
  dialogProps,
}: PlatePrepProps) {
  const classes = useStyles();
  const [stepFromURL, setCurrentStep] = useStateWithURLParams({
    paramName: 'step',
    paramType: 'number',
    defaultValue: 0,
  });
  const currentStep = clamp(stepFromURL ?? 0, 0, mixPreview.steps.length);
  const [selectedPlateId, setSelectedPlateId] = useState<string | null>(null);
  const [wellLocationUnderCursor, setWellLocationUnderCursor] =
    useState<WellLocationOnDeckItem | null>(null);
  const screenToPrintRef = useRef<HTMLDivElement | null>(null);
  const platePrepRef = useRef<HTMLDivElement | null>(null);

  const deckLayout = useMemo(() => new DeckLayout(mixPreview.deck), [mixPreview]);
  const mixStateCache = useMemo(
    () => new MixStateCache(mixPreview, { noLabwareMovements: true }),
    [mixPreview],
  );

  const liquidColors = useMemo(
    () =>
      LiquidColors.createUsingColorGraph(
        getDeckItemStates(deckLayout.deck),
        mixPreview.steps,
      ),
    [deckLayout, mixPreview],
  );

  // Note that the MixPreview never changes while the user interacts with the
  // screen. The MixPreview is fetched once and stored in state. It is the step
  // that affects the result.
  const currentMixState = useMemo(
    () => mixStateCache?.computeState(currentStep ?? 0),
    [mixStateCache, currentStep],
  );

  const handleSliderChange = useCallback(
    (stepNumber: number) => {
      debouncedSliderChangeEventLog(stepNumber);
      setCurrentStep(stepNumber);
    },
    [setCurrentStep],
  );

  const handleWellMouseEnter = useCallback(
    (loc: WellLocationOnDeckItem) => {
      logEvent('hover-well', SIMULATION_DETAILS_SETUP_TAB_ID, `C${loc.col}, R${loc.row}`);
      setWellLocationUnderCursor(loc);
    },
    [setWellLocationUnderCursor],
  );

  const handleWellMouseLeave = useCallback(
    () => setWellLocationUnderCursor(null),
    [setWellLocationUnderCursor],
  );

  const handleSelectPlateInSidebar = useCallback((clickedPlateIdInSidebar: string) => {
    logEvent(
      'select-plate-in-sidebar',
      SIMULATION_DETAILS_SETUP_TAB_ID,
      clickedPlateIdInSidebar,
    );
    setSelectedPlateId(clickedPlateIdInSidebar);
  }, []);

  const wellInfoToShowInRightPanel = useMemo(() => {
    if (wellLocationUnderCursor) {
      return {
        loc: wellLocationUnderCursor,
        contents: getWellContents(currentMixState.deck, wellLocationUnderCursor),
        hover: true,
      };
    }
    return null;
  }, [currentMixState, wellLocationUnderCursor]);

  const selectedPlate = useMemo(() => {
    const plates = platesOnly(currentMixState.deck.items);
    if (selectedPlateId) {
      return plates.find(plate => plate.id === selectedPlateId) || null;
    }
    // Return the first plate, e.g. when rendering screen for the first time
    return plates.length > 0 ? plates[0] : null;
  }, [currentMixState, selectedPlateId]);

  // If a user selects a plate, update the parent's state with the selected plate
  useEffect(() => {
    if (!dialogProps) {
      return;
    }
    dialogProps.handleSelectPlate(selectedPlate);

    // Default to the last step, as that's what users are interested in.
    setCurrentStep(mixPreview.steps.length);
  }, [dialogProps, selectedPlate, mixPreview.steps.length, setCurrentStep]);

  const hiddenContext = useContext(RouteHiddenContext);
  if (hiddenContext.hidden) {
    // Don't unnecessarily render the large React tree when
    // we are on a different tab of the Simulation Details.
    return null;
  }

  return (
    <>
      <div className={classes.platePrepScreen} ref={platePrepRef}>
        <div className={classes.sidebar}>
          <PlatesSidebar
            deckState={currentMixState}
            highlightedWellLocation={wellLocationUnderCursor}
            selectedPlateId={selectedPlate?.id ?? null}
            liquidColors={liquidColors}
            onPlateClick={handleSelectPlateInSidebar}
            onWellMouseEnter={handleWellMouseEnter}
            onWellMouseLeave={handleWellMouseLeave}
          />
        </div>
        <div className={classes.mainPanel}>
          {!dialogProps && (
            <StepSlider
              currentStep={currentStep ?? 0}
              steps={mixPreview.steps}
              keyPoints={NO_SLIDER_KEY_POINTS}
              timeElapsed={currentMixState.timeElapsed}
              onStepChange={handleSliderChange}
              deckItems={currentMixState.deck.items}
            />
          )}
          <div className={classes.plateAndRightPanel}>
            <div className={classes.plate}>
              {selectedPlate && (
                <PlateView
                  key={selectedPlate.id}
                  deckLayout={deckLayout}
                  highlightedWellLocation={wellLocationUnderCursor}
                  plate={selectedPlate}
                  liquidColors={liquidColors}
                  onWellMouseEnter={handleWellMouseEnter}
                  onWellMouseLeave={handleWellMouseLeave}
                />
              )}
            </div>
            <div className={classes.rightPanel}>
              <RightPanel wellInfo={wellInfoToShowInRightPanel} edgeInfo={null} />
            </div>
          </div>
        </div>
      </div>
      <div ref={screenToPrintRef} style={{ display: 'none' }}>
        <PlatePrepScreenPrintable
          selectedPlate={selectedPlate!}
          deckLayout={deckLayout}
          simulationName={simulationName}
          liquidColors={liquidColors}
        />
      </div>
      {!!showPrintButton && (
        <ReactToPrint
          trigger={() => {
            return (
              <Fab
                icon={<PrintIcon />}
                onClick={logPrintEvent}
                color="inherit"
                size="small"
              />
            );
          }}
          // When users click print, make the printable PlatePrep visible
          // and hide the regular PlatePrep not to break the layout
          onBeforeGetContent={() => {
            screenToPrintRef.current!.style.display = 'block';
            platePrepRef.current!.style.display = 'none';
            return;
          }}
          content={() => screenToPrintRef.current!}
          bodyClass={classes.printableBody}
          // After users close the print dialog, restore to normal PlatePrep layout
          onAfterPrint={() => {
            screenToPrintRef.current!.style.display = 'none';
            platePrepRef.current!.style.display = 'flex';
            return;
          }}
        />
      )}
    </>
  );
}

const useStyles = makeStylesHook({
  platePrepScreen: {
    display: 'flex',
    flex: 1,
    minHeight: 0,
  },
  sidebar: {
    backgroundColor: Colors.SIMULATION_DETAILS_BACKGROUND_GRAY,
    height: '100%',
    overflowY: 'scroll',
    width: '300px',
  },
  mainPanel: {
    backgroundColor: 'white',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
    padding: '16px',
  },
  plateAndRightPanel: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    height: '100%',
    marginTop: '8px',
  },
  plate: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
  },
  rightPanel: {
    display: 'flex',
    width: '200px',
  },
  printableBody: {
    color: 'black',
  },
});

export default PlatePrepScreen;
