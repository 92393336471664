import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

const TrackActiveContext = createContext<{
  activeId: string;
  register: (el: HTMLElement) => void;
  showById: (id: string) => void;
}>({ activeId: '', register: () => {}, showById: () => {} });

export function TrackActiveProvider({ children }: { children: ReactNode }) {
  const observer = useRef<IntersectionObserver>();
  const [activeId, setActiveId] = useState<string>('');

  useEffect(() => {
    observer.current = new IntersectionObserver(entries => {
      const intersecting = entries.find(entry => entry.isIntersecting);
      if (intersecting) {
        setActiveId(intersecting.target.id);
      }
    });

    return () => observer.current?.disconnect();
  }, []);

  const register = useCallback((element: HTMLElement | null) => {
    if (element) {
      observer.current?.observe(element);
    }
  }, []);

  const showById = useCallback((id: string) => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const value = useMemo(
    () => ({
      activeId,
      register,
      showById,
    }),
    [activeId, register, showById],
  );

  return (
    <TrackActiveContext.Provider value={value}>{children}</TrackActiveContext.Provider>
  );
}

export const useTrackActive = () => useContext(TrackActiveContext);
