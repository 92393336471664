import { useEffect } from 'react';

import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { UserTrackingInfo } from 'common/types/user';
import getGATrackingEnvironment from 'common/ui/getGATrackingEnvironment';
import isIntegrationTesting from 'common/ui/lib/IntegrationTesting';

export function getHeapTrackingID(): string | undefined {
  // TODO - Update this when we sunset GA. For now, we can
  // use the same environment logic.
  const env = getGATrackingEnvironment(window.location?.host);
  const TRACKING_ID_DICT: { [env: string]: string } = {
    production: '1151790707',
    staging: '276472794',
    dev: '2962653630',

    // For local testing, you'll want to uncomment the line below
    // localhost: '3880836044',
  };

  return TRACKING_ID_DICT[env];
}

const HEAP_SCRIPT_ID = 'heapLoader';

export function useHeapTracking(user?: UserTrackingInfo) {
  const isHeapEnabled = useFeatureToggle('HEAP_DATA_TRACKING');

  useEffect(() => {
    // We don't want to log hits from integration testing. Each run
    // counts as a unique users, which massively pollutes the analytics.
    const isAnIntegrationTest = isIntegrationTesting();

    const env = getGATrackingEnvironment(window.location?.host);
    // We don't have auth on per-user basis in anthahub, so we can continue without requiring in this case.
    const requireUserIdentity = !(env === 'anthahub' || env === 'unknown');
    if (requireUserIdentity && !user) {
      return;
    }

    // Ensure we don't add multiple scripts if we have alreaded loaded this one.
    const heapScript = document.getElementById(HEAP_SCRIPT_ID);

    if (!isHeapEnabled || isAnIntegrationTest || heapScript) {
      return;
    }

    try {
      const heapID = getHeapTrackingID();

      if (heapID) {
        const script = document.createElement('script');
        script.id = HEAP_SCRIPT_ID;
        script.type = 'text/javascript';
        script.async = true;
        script.text = ` window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
    heap.load("${heapID}");`;
        document.head.appendChild(script);
        if (user) {
          heap.identify(user.id);
          heap.addUserProperties({
            email: user.email, // email must be lowercase to associate with Heap email field
            IsSynthaceEmployee: user.isSynthaceEmployee,
            OrganisationName: user.organisationName,
          });
        }
      }
    } catch (e) {
      console.warn('Error initialising Heap Analytics: ', e);
    }
  }, [isHeapEnabled, user]);
}
