import React, { ReactNode, useMemo } from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import PlateMapGrid from 'client/app/apps/simulation-details/instructions/PlateMapGrid';
import { generateSimple9wellTemplate } from 'common/lib/defaultPlate';
import { PlateType } from 'common/types/plateType';
import { LayerPlate, Well } from 'common/types/steps';
import Colors from 'common/ui/Colors';
import { PlateIcon } from 'common/ui/icons/Plate';

function getDefaultPlate() {
  return {
    ...generateSimple9wellTemplate(),
    id: 'Default Plate',
    rows: 8,
    columns: 12,
    name: 'Unknown Plate',
  };
}

export default function PlateMap({
  name,
  content,
  plate = getDefaultPlate(),
  showConcentration,
  className,
  gridDecorators,
  showLocalSources,
}: {
  name: string;
  content: LayerPlate;
  plate: PlateType;
  showConcentration: boolean;
  className?: string;
  gridDecorators?: ReactNode;
  showLocalSources: boolean;
}) {
  const showSource = useMemo(() => {
    return showLocalSources
      ? (well: Well) => {
          return well.source?.plateName === name;
        }
      : undefined;
  }, [name, showLocalSources]);

  return (
    <PlateMapWrapper className={className}>
      <PlateName>
        <PlateIcon />
        <Typography variant="body1">
          {name} ({plate.name})
        </Typography>
      </PlateName>
      <TableWrapper>
        <StyledPlateMapGrid
          content={content}
          plate={plate}
          zoom={1}
          getWellState={well => well.added ?? well.final}
          getContextValue={
            showConcentration ? well => well.final.concentration : undefined
          }
          showSource={showSource}
          decorators={gridDecorators}
        />
      </TableWrapper>
    </PlateMapWrapper>
  );
}

const PlateMapWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'minmax(0, 1fr)',
  gridTemplateRows: 'auto 1fr',
});

const TableWrapper = styled('div')({
  overflow: 'auto',
  position: 'relative',
  border: `1px solid ${Colors.GREY_30}`,
});

const StyledPlateMapGrid = styled(PlateMapGrid)({
  width: '100%',
});

const PlateName = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(3),
  background: Colors.GREY_20,
  justifySelf: 'start',
  padding: spacing(3, 5, 3, 4),
  borderRadius: spacing(2, 2, 0, 0),
  border: `1px solid ${Colors.GREY_30}`,
  borderBottomWidth: 0,
}));
