import React, { useMemo, useState } from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import DeviceParams from 'client/app/apps/simulation-details/instructions/DeviceParams';
import DilutionTable from 'client/app/apps/simulation-details/instructions/DilutionTable';
import PlateMap from 'client/app/apps/simulation-details/instructions/PlateMap';
import { SerialDilutionDirectionIndicator } from 'client/app/apps/simulation-details/instructions/SerialDilutionDirectionIndicator';
import { PlateType } from 'common/types/plateType';
import { LayerPlate, Step } from 'common/types/steps';
import Tabs, { TabsInfo } from 'common/ui/components/Tabs';
import { useTrackActive } from 'common/ui/components/TrackActive';

type Props = {
  step: Step;
  plates: Record<string, PlateType>;
  number?: number;
  showConcentration: boolean;
  elementName?: string;
};

enum Tab {
  DILUTION_TABLE,
  PLATE_MAPS,
}

const tabsInfo: TabsInfo<Tab> = [
  {
    label: 'Plate Map',
    value: Tab.PLATE_MAPS,
  },
  {
    label: 'Dilution Table',
    value: Tab.DILUTION_TABLE,
  },
];

export default function InstructionsStep({
  step,
  plates,
  number = 1,
  showConcentration,
  elementName,
}: Props) {
  const [activeTab, setActiveTab] = useState(Tab.PLATE_MAPS);
  const isDilution = step.type === 'normalization' || step.type === 'directDilution';
  const isRunDevice = step.type === 'runDevice';

  const layersForDisplay = useMemo(() => {
    // Serial dilution steps separate each transfer into a separate layer,
    // but we want to combine them for display purposes.
    if (step.type === 'serialDilution') {
      const [diluentLayer, ...dilutedLayers] = step.layers;

      diluentLayer.plates;

      const mergedDilutedLayer = dilutedLayers.reduce((acc, curr) => ({
        ...acc,
        plates: mergePlateMaps(acc.plates, curr.plates),
      }));

      return [diluentLayer, mergedDilutedLayer];
    }

    return step.layers;
  }, [step.layers, step.type]);

  const { register } = useTrackActive();

  return (
    <StepWrapper>
      <Typography variant="h1" id={step.id} ref={register}>
        Step {number}: {elementName ?? step.name}
        {elementName && <StepType variant="body2">{step.name}</StepType>}
      </Typography>
      {step.type === 'prompt' && step.meta?.message && (
        <Typography variant="h5">{step.meta?.message}</Typography>
      )}

      {isDilution && (
        <Tabs tabsInfo={tabsInfo} activeTab={activeTab} onChangeTab={setActiveTab} />
      )}
      {activeTab === Tab.DILUTION_TABLE && <DilutionTable step={step} />}
      {activeTab === Tab.PLATE_MAPS &&
        layersForDisplay.map((layer, layerIndex) =>
          Object.entries(layer.plates).map(([plate, content]) => (
            <>
              {isRunDevice && <DeviceParams params={layer.deviceParams} />}

              <PlateMap
                key={plate}
                name={plate}
                plate={plates[content.id]}
                content={content}
                showConcentration={showConcentration}
                showLocalSources={step.type === 'serialDilution'}
                gridDecorators={
                  step.type === 'serialDilution' && layerIndex === 1 ? (
                    <SerialDilutionDirectionIndicator
                      plate={content}
                      direction={step.meta?.direction}
                    />
                  ) : undefined
                }
              />
            </>
          )),
        )}
    </StepWrapper>
  );
}

export function mergePlateMaps(
  a: Record<string, LayerPlate>,
  b: Record<string, LayerPlate>,
) {
  const names = new Set([...Object.keys(a), ...Object.keys(b)]);

  const result: Record<string, LayerPlate> = {};

  names.forEach(name => {
    result[name] = {
      ...(a[name] ?? b[name]),
      wells: {
        ...a[name]?.wells,
        ...b[name]?.wells,
      },
    };
  });

  return result;
}

const StepWrapper = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(6),
}));

const StepType = styled(Typography)(({ theme: { palette, spacing } }) => ({
  color: palette.text.secondary,
  marginTop: spacing(3),
}));
